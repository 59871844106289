






























import { Component, Mixins } from 'vue-property-decorator';
import DrawerComponent from '@common-src/mixins/drawer-component';
import { ViewModeType } from '@common-src/model/enum';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { AnnouncementListEntityModel } from '@common-src/entity-model/announcement-list-entity';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import AnnouncementService from '@common-src/service/announcement';
import '@wangeditor/editor/dist/css/style.css';

@Component({
    components: {
        'Editor': Editor,
        'Toolbar': Toolbar
    }
})
export default class AnnouncementEditDrawer extends Mixins(DrawerComponent, FormDialogComponent) {
    AnnouncementListEntityModel = AnnouncementListEntityModel;
    mode: string = 'edit';
    drawerShow(model: any, viewMode: ViewModeType) {
        this.viewMode = viewMode;
        this.mode = 'edit';
        this.drawerTitle = viewMode === ViewModeType.UPDATE ? '编辑公告' : '新增公告';
        this.drawerOpen(model.id, model.name);
        this.$nextTick(() => {
            if (this.jtlForm) {
                this.jtlForm.initForm(model, viewMode);
            }
        });
    }

    save(type:number) {
        if (!this.jtlForm) {
            return;
        }
        return this.jtlForm.submitForm().then((ret) => {
            const model = new AnnouncementListEntityModel().toModel(this.jtlForm.formModel);

            if (this.viewMode === ViewModeType.NEW) {
                AnnouncementService.create(model, type).then((res) => {
                    this.$message.success('添加成功');
                    this.$emit('dialogOK');
                    this.drawerClose();
                });
            }

            if (this.viewMode === ViewModeType.UPDATE) {
                AnnouncementService.update(model, type).then((res) => {
                    this.$message.success('保存成功');
                    this.$emit('dialogOK');
                    this.drawerClose();
                });
            }
        }).catch(err => {
            throw err;
        });
    }
}
