
import { BaseEntityModel } from '@common-src/model/base-model';
import { ColumnProps } from 'ant-design-vue/lib/table';
import { FormControl, FormControlType } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import OrgService from '@common-src/service/org';

export class AnnouncementListEntityModel extends BaseEntityModel {
    @FormControl({
        label: '标题',
        type: FormControlType.TEXT,
        layout: { labelCol: 2, wrapperCol: 20, width: '100% ' },
        required: true,
        key: 'title'
    })
    title: string = null;

    @FormControl({
        label: '正文',
        type: FormControlType.EDITOR,
        layout: { labelCol: 2, wrapperCol: 20, width: '100% ' },
        required: false,
        height: 400,
        key: 'content'
    })
    content: any = null;

    @FormControl({
        label: '附件',
        type: FormControlType.UPLOAD,
        layout: { labelCol: 2, wrapperCol: 20, width: '100% ' },
        key: 'files'
    })
    files: any = [];

    @FormControl({
        label: '接收者类型',
        type: FormControlType.RADIO_GROUP,
        options: [{
            name: '组织',
            value: 0
        }],
        layout: { labelCol: 2, wrapperCol: 20, width: '100% ' },
        required: true,
        key: 'deliverType'
    })
    deliverType: number = 0;

    @FormControl({
        label: '发布范围',
        type: FormControlType.TREE_SELECT,
        mode: 'multiple',
        optionsPromise: OrgService.getSelectOrgTree,
        layout: { labelCol: 2, wrapperCol: 20, width: '100% ' },
        required: true,
        key: 'publishRange'
    })
    publishRange: string = null;
    id: string = null;
    status: string = null;
    top: boolean = null;

    static getTableColumns(): ColumnProps[] {
        return [
            {
                title: '序号',
                dataIndex: 'index',
                width: 80
            },
            {
                title: '标题',
                dataIndex: 'title'
            },
            {
                title: '创建日期',
                dataIndex: 'createdTime',
                width: 200
            },
            {
                title: '状态',
                dataIndex: 'status',
                scopedSlots: { customRender: 'status' },
                width: 150
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
                width: 220
            }
        ];
    }
}

export class AnnouncementListFilterModel extends QueryPageModel {
    @QueryControl({
        label: '时间',
        type: QueryControlType.SELECT_DATE
    })
    dateTime: any = null;

    @QueryControl({
        label: '标题',
        type: QueryControlType.TEXT
    })
    title: string = null;

    toService() {
        const data:any = {};
        data.params = {
            title: this.title || undefined,
            startTime: !_.isEmpty(this.dateTime) ? this.dateTime[0].format('YYYY-MM-DD HH:mm:ss') : undefined,
            endTime: !_.isEmpty(this.dateTime) ? this.dateTime[1].format('YYYY-MM-DD HH:mm:ss') : undefined
        };
        return data;
    }
}
