






























































import { Component, Mixins } from 'vue-property-decorator';
import GroupComponent from '@common-src/mixins/group-component';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { AnnouncementListEntityModel, AnnouncementListFilterModel } from '@common-src/entity-model/announcement-list-entity';
import AnnouncementService from '@common-src/service/announcement';
import AnnouncementEditDrawer from './announcement-edit-drawer.vue';
import { ViewModeType } from '@common-src/model/enum';

@Component({
    components: {
        'announcement-edit-drawer': AnnouncementEditDrawer
    }
})
export default class AnnouncementListComponent extends Mixins(TableDialogFormComponent, GroupComponent) {
    AnnouncementListEntityModel = AnnouncementListEntityModel;

    created() {
        this.initTable({
            service: AnnouncementService,
            queryModel: new AnnouncementListFilterModel(),
            tableColumns: AnnouncementListEntityModel.getTableColumns()
        });
        this.getList();
    }

    editHandler(model: AnnouncementListEntityModel) {
        AnnouncementService.retrieve(model.id).then(res => {
            (this.$refs.announcementEditDrawer as AnnouncementEditDrawer).drawerShow(res, ViewModeType.UPDATE);
        });
    }

    addHandler() {
        const model = new AnnouncementListEntityModel();
        (this.$refs.announcementEditDrawer as AnnouncementEditDrawer).drawerShow(model, ViewModeType.NEW);
    }

    executeHandler(model:any, type:number) {
        AnnouncementService.execute(model.id, type).then(res => {
            this.$message.success('操作成功');
            this.getList();
        });
    }

    formatStatus(text) {
        switch (text) {
            case 'PUBLISHED':
                return '已发布';
            case 'DRAFT':
                return '草稿';
            case 'CANCELED':
                return '已撤回';
            case 'DELETED':
                return '删除';
            default:
                return text;
        }
    }
}

