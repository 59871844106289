var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: _vm.drawerTitle,
        placement: _vm.placement,
        width: "80vw",
        closable: _vm.closable,
        visible: _vm.drawerVisible,
        "destroy-on-close": true,
      },
      on: { close: _vm.drawerClose },
    },
    [
      _c(
        "div",
        { staticClass: "table-component" },
        [
          _c("jtl-form", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.mode === "edit",
                expression: "mode === 'edit'",
              },
            ],
            ref: _vm.JTL.CONSTANT.DEFAULT_FORM_NAME,
          }),
        ],
        1
      ),
      _vm.mode === "preview"
        ? [
            _c("div", {
              domProps: { innerHTML: _vm._s(this.jtlForm.formModel.content) },
            }),
          ]
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { float: "right" } },
        [
          _vm.mode === "edit"
            ? [
                _c(
                  "jtl-button",
                  {
                    staticStyle: { "margin-right": "16px" },
                    attrs: { "click-prop": _vm.drawerClose.bind(this) },
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "jtl-button",
                  {
                    staticStyle: { "margin-right": "16px" },
                    on: {
                      click: function ($event) {
                        _vm.mode = "preview"
                      },
                    },
                  },
                  [_vm._v("预览")]
                ),
                _c(
                  "jtl-button",
                  {
                    staticStyle: { "margin-right": "16px" },
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.save(0)
                      },
                    },
                  },
                  [_vm._v("保存并发布")]
                ),
                _c(
                  "jtl-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.save(1)
                      },
                    },
                  },
                  [_vm._v("仅保存")]
                ),
              ]
            : _c(
                "jtl-button",
                {
                  staticStyle: { "margin-right": "16px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.mode = "edit"
                    },
                  },
                },
                [_vm._v("编辑")]
              ),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }