
import { AnnouncementListEntityModel, AnnouncementListFilterModel } from '@common-src/entity-model/announcement-list-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, put, del } from './request';

const URL_PATH = `${MESSAGE_BASE_REQUEST_PATH.slice(0, -8)}/notice`;

class AnnouncementService implements ICRUDQ<AnnouncementListEntityModel, AnnouncementListFilterModel> {
    async create(model: AnnouncementListEntityModel, type?:number):Promise<AnnouncementListEntityModel> {
        const url = `${URL_PATH}/add/${type}`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<AnnouncementListEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new AnnouncementListEntityModel().toModel(res);
    }

    async update(model: AnnouncementListEntityModel, type?:number):Promise<AnnouncementListEntityModel> {
        const url = `${URL_PATH}/update/${type}`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: AnnouncementListEntityModel):Promise<AnnouncementListEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: AnnouncementListFilterModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/manager/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new AnnouncementListEntityModel().toModel(item));
        res.items = _.map(res.items, (item, index) => ({ index: index + 1, ...item }));
        return res;
    }

    async execute(id:string, type:any) {
        const url = `${URL_PATH}/execute`;
        const res = await post(url, {
            id,
            operation: type
        });
        return res;
    }
}

export default new AnnouncementService();
